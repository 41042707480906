const enUS = {
  btn: {
    accept: 'I accept',
    add: 'Add',
    archive: 'Archive messages',
    back: 'Back',
    cancel: 'Cancel',
    cancel_change: 'Cancel change',
    cancelManual: 'Cancel',
    change: 'Change',
    clear: 'Clear',
    close: 'Close',
    confirm: 'Confirm',
    copy: 'Copy',
    copyLink: 'Copy Link',
    delete: 'Delete',
    edit: 'Edit',
    export_xls: 'Export XLS',
    filter: 'Filter',
    finish: 'Finish',
    initAttendance: 'Start Attendance',
    integrationManual: 'Integration Manual',
    new: 'New',
    no: 'No',
    not_accept: "I don't accept",
    notify_participant: 'Notify participant',
    off: 'Turned off',
    on: 'Turned on',
    performedManual: 'Performed',
    prescriptionDigital: 'Prescription digital',
    reactivate: 'Reactivate',
    resendMailMedic: 'Resend Email',
    resendMailPacient: 'Resend Email',
    restore: 'Restore',
    save: 'Save',
    send: 'Send',
    sendFile: 'Send File',
    share_event: 'Share Event',
    unarchive: 'Unarchive messages',
    update_payment: 'Update Payment',
    yes: 'Yes',
  },
  country: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia, Plurinational State of',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: "Cote d'Ivoire",
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronesia, Federated States of Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic of Persian Gulf',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: "Korea, Democratic People's Republic of Korea",
    KR: 'Korea, Republic of South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libyan Arab Jamahiriya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory, Occupied',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena, Ascension and Tristan Da Cunha',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SY: 'Syrian Arab Republic',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania, United Republic of Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See (Vatican City State)',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela, Bolivarian Republic of Venezuela',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  label: {
    accent: 'Featured color?',
    access_to_reports: 'Have access to reports?',
    account_token: 'Account token',
    actions: 'Actions',
    activeStatus: 'Active status',
    add_participant: 'Add Participant',
    add_payment: 'Add Payment',
    additional_minute_recorded_allowed: 'Allow additional',
    additional_minute_value: 'Additional Value',
    additional_minutes_allowed: 'Allow additional',
    additional_recorded_minute_value: 'Additional Value',
    address: 'Address',
    adress: 'Address',
    after_payment: 'After payment',
    all_users: 'Send to all users',
    allow_access_appointment_before: 'Time in minutes for prior clearance to access the consultation room',
    allow_access_attendance_data: 'Allowed to access attendance data',
    allow_emit_nfe: 'Issue electronic invoice?',
    allow_holder_register_dependents: 'Allow holder to register dependent in office?',
    allow_holder_register_dependents_clinical_data:
      'Allow holder to configure access to clinical data of dependents with login?',
    allow_manager_to_create_user: 'Allow manager to create patient?',
    allow_medical_prescription_virtual: 'Allow medical digital prescription note?',
    allow_notify_nps: 'Allow NPS notifies?',
    allow_notpaid_attendance: 'Allow not paid attendance',
    allow_patient_edit_profile: 'Allow patient to edit profile?',
    allow_patient_payment: "Payment block include in patient's appointment creation",
    allow_patient_to_register: 'Allow patient to register?',
    allow_professional_edit_profile: 'Allow doctor to edit profile?',
    allow_register_dependent_user: 'Allow to register dependents?',
    allow_room_exit_without_finish: 'Finish attendance professional',
    allow_room_files: 'Allow files',
    allow_room_notes: 'Allow Appointment Record',
    allow_room_participant_picture: 'Allow professional to take pictures of pacient',
    allow_room_patient_history: 'Display patient history',
    allow_room_recording: 'Allow recording',
    allow_room_screen_share: 'Allow screen share',
    allow_room_upshot: 'Allow upshot',
    allow_send_receive_message: 'Allow sending/receiving messages',
    allow_specifying_dependent: 'Allow specifying dependent registration limit per patient?',
    allow_to_create_ehr_events_on_patient: 'Allow to view or create EHR events on patient?',
    allow_to_modify_appointment: 'Allow to modify Appointments forbidden by SLA',
    allow_to_use_nps: 'Allow NPS?',
    allowed_to_create_appointment_out_of_availability_by_the_clinic:
      'Allowed to create appointment out of availability by the clinic',
    allows_creating_appointments_at_same_time_in_calendar: 'Allow creating appoinments at same time in calendar',
    allows_hidden_button_schedule: 'Hide schedule button in attendance',
    allows_hidden_professional_name: 'Hide professionals name in patient appointment',
    amount: 'amount',
    amount_paid: 'Amount paid',
    api_key: 'API Key',
    apply_children: 'Apply Permissions and Timezone config to dependants?',
    appointment_duration: 'Time in minutes for the standard session, used for scheduling',
    appointment_loading: 'Finishing the registration of appointments, wait!',
    appointment_reason: 'Scheduled Emergency Care',
    appointment_time: 'Appointment Time',
    appointment_type: 'Appointment Reason',
    appointments_finished: 'Appointments finished',
    artificial_intelligence: 'Artificial Intelligence',
    artificial_intelligence_send_queue: 'At what point does the AI ​​send the patient to the queue?',
    artificial_moment_probability:
      'At the moment when the highest probability of the hypotheses is equal to or greater than',
    artificial_patient_question_rounds: 'After the patient question rounds',
    attendace_out_availability: 'Allowed to create schedule out of availability',
    attendance: 'Attendance',
    attendance_average_minutes: 'Average Mining Count',
    attendance_average_minutes_recorded: 'Average Recording Minutes',
    attendance_average_minutos: 'Average frequency minutes',
    attendance_average_minutos_recorded: 'Average frequency minutes recorded',
    attendance_counter: 'Show counter',
    attendance_counter_behavior: 'Counter behavior',
    attendance_counter_behavior_options: {
      progressive: 'Progressive',
      regressive: 'Regressive',
      regressiveWithAddtional: 'Regressive with additional time',
    },
    attendance_counter_options: {
      both: 'Both',
      no: 'No',
      patient: 'Patient',
      professional: 'Professional',
    },
    attendance_value: 'Consultation Value',
    average: 'Average',
    background_login_clinica: 'Clinic Login Background image',
    background_login_consultorio: 'Attendance login background image',
    bank_data: 'Bank Data',
    banners: 'Banners (available on the website)',
    bill_type: 'Type',
    billing: 'Billing',
    billing_agencia: 'Agency',
    billing_agencia_dv: 'Agency check digit',
    billing_bank: 'Bank Account',
    billing_bank_account_type: 'Account type',
    billing_bank_code: 'Bank code',
    billing_conta: 'Account number',
    billing_conta_dv: 'Account check digit',
    billing_document_number: 'Account CPF or CNPJ',
    billing_legal_name: 'Full name or company name',
    billing_transfer_day: 'Which day automatic transfers should be made',
    billing_transfer_interval:
      'How often should the available amounts be automatically transferred to the bank account',
    billing_use: 'Use Payment?',
    billing_use_emergency: 'Use payment on virtual line?',
    bio: 'Biography',
    birth_date: 'Date of Birth',
    blockedStatus: 'Blocked status',
    browser: 'Browser',
    business_contact: 'Business contact',
    callback: 'Callback',
    callback_url_for_query_notifications: 'Callback URL for session notifications',
    cancel_reason: 'Cancel Reason',
    cancel_time: 'Cancel Date / Time',
    cell_phone: 'Mobile',
    charge_participant: 'Request payment from the participant',
    cid10: 'CID10',
    city: 'City',
    closeAttendanceReason: 'Type the reasons to finish attendance',
    cnpj: 'CNPJ',
    color_primary: 'Primary application color',
    color_top: 'Top primary application color',
    comission_type: 'Commission type',
    comission_value: 'Commission amount',
    comments: 'Comments',
    commission: 'Commission',
    companion_id: 'Escort',
    company_id: 'Company',
    complement: 'Complement',
    connections: 'Session details',
    consultation_time: 'Total consultation duration',
    consultation_time_doctor: 'Total professional time in the care room',
    consultation_time_patient: 'Total patient time in the care room',
    count_days_after_payment: 'Number of days after payment',
    country_contact: 'Country code',
    countryname: 'Country',
    cpf: 'CPF',
    creation_date: 'Creation date',
    credit_card: 'Credit card',
    crm: 'CRM',
    DadosPessoais: 'Personal Data',
    date: 'Date',
    date_start_creation: 'Creation start date',
    date_start_deletion: 'Deletion start date',
    day: 'Day',
    days: 'Days',
    ddi: 'DDI',
    default_payment_value: 'Default attendance value',
    default_payment_value_emergency: 'Default attendance value on virtual line',
    default_time: 'Default time',
    delete: 'Delete',
    deny_access_appointment_after: 'Time in minutes to block access to the consultation room after your start time',
    dependants: 'Dependants',
    dependent_limit: 'Dependent limit',
    description: 'Description',
    destination_address: 'Destination Address',
    developer_setup: 'Developer Configuration',
    display_guideline_for_professional: 'Display guideline for professional?',
    display_more_options: 'Display More Options',
    doctor: 'Professional (s)',
    doctor_id: 'Professional',
    doctor_time: 'Professional Time',
    doctorManager: 'Allow professional to manage the clinic',
    duration: 'Duration',
    duration_days: 'Duration Days',
    duration_minutes: 'minutes',
    edit: 'Edit',
    edit_shortcut: 'Edit shortcut',
    ehr: 'Electronic Health Record',
    elective: 'Elective',
    email: 'E-mail',
    emergency: 'Emergency',
    emergency_queue_start: 'Start Queue',
    emergency_service: 'Emergency Service',
    enabled: 'Enabled',
    encryption_key: 'Encryption Key',
    end_date: 'End Date',
    end_date_payment: 'End Date Payment',
    end_date_time: 'End Date / Time',
    end_time: 'End Time',
    ended_appointment_after_duration:
      'Defines whether the session should end automatically after the maximum time has elapsed',
    endereco: 'Address',
    endpoint: 'Endpoint',
    entered: 'Entered',
    error_notify_participant: 'Error in notifying participant',
    error_prescription: 'Prescription Unavailable',
    event: 'Event',
    event_date: 'Event Date',
    event_description: 'Description',
    event_item: 'Archive / Exam',
    event_link: 'Link',
    event_name: 'Event Name',
    event_notes: 'Notes',
    event_remetent: 'Remetent',
    events_patient: {
      medical_referral: 'Medical referral',
    },
    expiration_date: 'Expiration Date',
    field: 'Field',
    file: 'Files',
    finance_mail_address: 'Mail adress to receive payment information.',
    financial: 'Financial',
    finish_attendance: 'Finish Attendance',
    finish_professional_protocol: 'Remove the professional from the virtual queue',
    flow_level: 'Queue Type',
    form: 'Form',
    forms: 'Forms',
    forms_saved: 'Saved Forms',
    four_day: 'Four Days',
    galax_hash: 'Galax Hash',
    galax_id: 'Galax Id',
    gender: 'Sex',
    geolocation: 'Geolocation',
    goodAfternoon: 'Good Afternoon',
    goodEvening: 'Good Evening',
    goodMorning: 'Good Morning',
    group: 'Group',
    groups: 'Queues',
    guideline: 'Guideline',
    h_before: 'Hour (s) before',
    history: 'History',
    history_item_type_APPOINTMENT: 'Attendance',
    history_item_type_ARCHIVE: 'Archive',
    history_item_type_DIAGNOSTIC: 'Exam',
    history_item_type_EVENT: 'Event',
    history_item_type_EXAM: 'Exam',
    history_item_type_LINK: 'Link',
    history_item_type_MEDICAL_REFERRAL: 'Medical referral',
    history_item_type_NOTES: 'Notes',
    history_item_type_PRESCRIPTION: 'Prescription Digital',
    history_type_event: 'Event Type',
    holder_settings: 'Holder / Dependents',
    hour: 'Hours',
    hours: 'Hours',
    id: 'ID',
    images: 'Images',
    in_attendance: 'In attendance',
    in_payment: 'In payment',
    initial_attendance_quantity: 'Initial session Quantity',
    installments: 'Installments',
    integration: 'Integration',
    integration_id: 'Integration id',
    integrationManual: 'Integration Manual',
    integrationManualSubtitle: "Enter the patient's CPF below",
    intermediary_account_number: 'Payment intermediary account number',
    ip: 'IP',
    is_landing: 'Available at landing page?',
    is_transaction_owner: 'Transaction owner',
    landing_page: 'Landing Page',
    latitude: 'Latitude',
    left: 'Out',
    license_council: 'License Council',
    limit: 'Limit',
    link: 'Link',
    load_more: 'See more ...',
    loading: 'Loading...',
    location_source: 'Source',
    location_source_COORDINATES: 'Coordinates',
    location_source_IP: 'IP',
    login: 'Login',
    logo: 'Logo',
    longitude: 'Longitude',
    m_before: 'Minute (s) before',
    mail: 'E-mail',
    manage_all_professionals: 'Manage all professionals',
    manage_appointments: 'Manage Appointments?',
    manage_availability: 'Manage Availability?',
    manage_emergency: 'Manage Emergency?',
    manage_professionals: 'Allow to include or edit professional data',
    manage_subscription_plans: 'Manage plans?',
    manager: 'Manager',
    max_additional_minute: 'Maximum Additional',
    max_additional_recorded_minute: 'Maximum Additional',
    max_appointment_duration:
      'Maximum consultation time in minutes, if the time is reached the consultation can be completed',
    max_attendance_minutes: 'Maximum participation minutes',
    max_register_depedents: 'Maximum limit of dependents that can be registered',
    maximum_credit_and_card_installments: 'Maximum credit card installments',
    medical_assistent: 'Assistant Physician',
    medical_specialty: 'Specialty',
    medicines: 'Medicines',
    message_integration_mails: 'Emails for notifications of integration notices',
    message_module_email_address: 'Email address for messages notifications',
    message_module_enabled: 'Messages enabled?',
    messages: 'Messages',
    mini_logo: 'Mini logo',
    minutes: 'Minutes',
    minutes_consultation: 'Session Minutes',
    minutes_quantity: 'Number of minutes',
    minutes_value: 'Minutes value',
    module_used: 'Create user/login when adding a new patient/professional the system?',
    moment_created_nfe: 'Moment of creation of the NFe',
    month: 'Month',
    more_options: 'More Options',
    multiple: 'Multiple',
    name: 'Name',
    namecpf: 'Name | CPF',
    neighborhood: 'Neighborhood',
    new: 'Add',
    new_event: 'New Event',
    new_shortcut: 'New shortcut',
    no_data_available: 'No data available',
    notes: 'Notes',
    notification_channel: 'Notification Channel',
    notification_emergency_patient: 'Send notification to patient on virtual line?',
    notification_post_appointment: 'Send mail post appointment?',
    notification_post_appointment_files: 'Send mail post appointment with files sent by professional?',
    notification_post_appointment_orientation: 'Send mail post appointment with orientations to patient?',
    notification_settings: 'Notification Settings',
    notify_appointment_cancelled_doctor: 'Notify professional when canceling appointment?',
    notify_appointment_cancelled_patient: 'Notify patient when canceling appointment?',
    notify_appointment_doctor: 'Notify professional about appointment?',
    notify_appointment_patient: 'Notify patient about appointment?',
    notify_service_unavailable: 'Notification service unavailable at the moment',
    notify_user_doctor: 'Notify professional when editing user?',
    notify_user_patient: 'Notify patient when editing user?',
    nps_rating: 'Rating',
    nps_ratings: 'Ratings',
    number: 'Number',
    number_of_dependents: 'Number of dependents that the holder can register',
    one_file: 'File(s)',
    only_reading: 'Only reading',
    open_recurrence: 'Open Recurrence',
    order: 'Order',
    order_arrival: 'Order of arrival',
    orientation: 'Guidelines to Patient',
    other: 'OTHERS',
    other_participant: 'Other Participants',
    parent: 'Parent Company',
    parent_access_clinical_data: 'Share clinical data with user responsible?',
    parent_id: 'Responsible',
    participant: 'Participant',
    participation: 'Participation',
    partner_id: 'Partner Id',
    patient: 'Patients',
    patient_id: 'Patient',
    patient_time: 'Patient Time',
    patient_time_end: "Patient's departure time",
    patient_time_start: "Patient's entry time",
    patients_line: 'Patients on Line',
    payer: 'Payer',
    payment_attendance: 'Service the value',
    payment_date: 'Payment Date',
    payment_date_time: 'Payment date',
    payment_status: 'Payment Status',
    payment_type: 'Billing Type',
    payment_type_accept: {
      credit_card_or_pix: 'Pagamento apenas com Cartão de Credito ou PIX',
      local: 'Pagamento no local',
      no_charge: 'Sem cobrança',
    },
    payment_types_accepted: 'Payment methods accepted at checkout',
    payment_value_charged: 'Value Charged',
    payment_value_paid: 'Value Paid',
    pending_payment: 'pending payment',
    period: 'Period',
    period_days: 'Validity period in days',
    permalink: 'Profile Link',
    permissions: 'Permissions',
    personaldata: 'Personal Data',
    picture: 'Photo',
    pix: 'Pix',
    place_text: 'Insert text here...',
    plan: 'Plan',
    plan_id: 'plan ID',
    plandata: 'Plan Data',
    powered_by_dav: 'Developed with ❤ by Doutor ao Vivo',
    preferential: 'Preferential',
    prescription_data: 'Prescription Data',
    prescription_description_generate: 'Prescription by {0}',
    prescription_include_address_field: 'Include Address',
    prescription_settings: 'medical documents note',
    prescription_virtual_include_address: 'Include address in digital prescription note?',
    priority: 'Priority',
    probability: 'Probability',
    profession: 'Profession',
    professional: 'Professional',
    professional_registration: 'Profissional Register',
    professional_time_end: "Doctor's departure time",
    professional_time_start: "Doctor's entry time",
    professional_view_patient_history_office:
      'Allow professionals to view patients electronic medical records in doctor office',
    professional_view_patient_history_options: {
      all: 'All',
      disabled: 'Disabled',
      only_from_professional: 'Only from professionals',
    },
    protocol: 'Protocol',
    provider: 'Provider',
    providers: 'Providers',
    quantity: 'Quantity',
    ranges: 'Tracks',
    rating_text: 'Comments',
    reason: 'Main Complaint',
    reason_finished: 'Reason',
    recording_consultation: 'Recording Consultation',
    recording_time: 'Recording Time',
    recordings: 'Recordings',
    recurrence: 'Recurrency',
    recurrence_description: 'Select below the type of recurrence and the amount in which it repeats',
    recurrence_list: 'Recurrence list',
    recurrence_note:
      '<strong> Note: </strong> If you want to change the appointment date, remove all recurrences listed!',
    region: 'UF',
    registration: 'Registration',
    registration_state: 'Register State',
    remember: 'Remind me:',
    remetent: 'Remetent',
    repeat_recurrence: 'Repeat every:',
    representative: 'Representante',
    representative_id: 'representative ID',
    reset: 'Reset and send new access',
    reset_password_success: 'New password sent successfully',
    reset_password_unvailable: 'Unable to reset access',
    reset_subtitle: 'Send mail with new password to the user',
    reset_title: 'Reset access',
    restric_edit_info: 'Schedule with modification restriction by contract.',
    result_description: 'Result',
    role_participant: 'Participant Type',
    room_settings: 'Attendance Room Settings',
    scale: 'Scale',
    schedule_datetime: 'Schedule date/time',
    schedules: 'Schedules',
    scheduling: 'Scheduling',
    scheduling_settings: 'Schedule Settings',
    screening_artificial_intelligence: 'Screening with Artificial Intelligence',
    search: 'Search',
    select_channels_and_time: 'Select channels and time before sending',
    select_notification_channels: 'Select the notification channels',
    select_time: 'Select time',
    select_time_confirm: 'Select time confirm',
    selectedItemText: 'Message text',
    selectLanguage: 'Select language',
    self_appointment_referral_only: 'Specialist Self-Scheduling by Referral Only',
    self_schedule_with_referral: 'Self-Scheduling by referral?',
    send_notification: 'Send Notification',
    service_queue: 'Service Queue',
    settings: 'Settings',
    share_event: 'Share Event',
    share_link: 'Share Link',
    shortcut: 'Shortcut',
    show_availability_for_inoffice_professional: 'Show availability for In-office Professional?',
    show_availability_for_inoffice_professional_status: {
      allow_edit: 'Allow Edit',
      allow_ready: 'Allow Ready',
      hide: 'Hide',
    },
    signup: 'Sign Up',
    simple: 'Simple',
    single_sale_plan_with_periodicity: 'Single sale plan with periodicity?',
    situation: 'Situation',
    size: 'Size',
    sms: 'SMS',
    smu_code: 'Código SMU',
    social_name: 'Social Name',
    source: 'Channel',
    source_options: {
      clinic: 'Clinic',
      emergency: 'Emergency',
      patient: 'Self scheduling',
    },
    specialty: 'Specialty',
    split_percentage: 'Split percentage',
    split_with_professional: 'Split with professional',
    start_date: 'Start Date',
    start_date_attendance: 'Start Date (Attendance)',
    start_date_payment: 'Start Date Payment',
    start_date_scheduled: 'Start Date (Schedule)',
    start_date_time: 'Start Date / Time',
    start_time: 'Start Time',
    state: 'State',
    status: 'Status',
    status_appointment: 'Scheduling Status',
    status_payment: 'Payment Status',
    status_plan: 'Status plan',
    street: 'Street',
    tag_id: 'Group id',
    telemedicine: 'Telemedicine',
    telephone: 'Telephone',
    term_of_use_accept: 'Terms of use accept',
    term_use_agree_with: 'I have read and agree with ',
    text_term_use_accept: 'I have read and agree with <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'The {0} has updated the <b rel="noreferrer nofollow">Terms of Use</b>. We are engaged in protect our users security and privacy.',
    theme_settings: 'Theme Settings',
    time: 'Time',
    time_course: 'Time Course',
    time_inactivity_to_disconnect: 'Maximum inactivity time during attendance in minutes',
    timeline: 'Timeline',
    timeToSchedule: 'Schedule',
    timezone: 'Timezone',
    title: 'Title',
    to: 'To',
    total: 'Total',
    type: 'Type',
    type_ratings: 'Rating Type',
    uhc_id: 'Universal Healthcare ID',
    upload_file: 'File upload',
    upload_files: 'Files upload',
    upshot: 'Upshot',
    use_date: 'Use date',
    use_financial: 'Uses financial?',
    use_notification_channel_patient: 'Send a reminder about scheduled appointment to patient?',
    use_notification_channel_professional: 'Send a reminder about scheduled appointment to professional?',
    use_notification_confirm_patient: 'Send attendance confirmation reminder to patient?',
    use_voucher_track: 'People who used this voucher',
    user_settings: 'User Settings',
    valid_for: 'Valid for {0} days',
    valid_until: 'Valid until',
    value: 'Value',
    value_attendance: 'Charge for Service',
    value_discount: 'Valud discount',
    view_messages: 'View Messages',
    voucher: 'Voucher Code',
    voucher_discount: 'Value',
    voucher_expire: 'Expiration date',
    voucher_limit_use: 'Usage Limit',
    voucher_type: 'Voucher usage type',
    voucher_type_discount: 'Discount Type',
    waiting_room_video: 'Waiting room video id',
    week: 'Week',
    whatsapp: 'WhatsApp',
    who_we_are_picture: 'Who we are (displayed on website)',
    year: 'Year',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Access Denied',
    appointment_reason: 'Select a schedule type',
    appointmentDateEnd: 'End date {0} cannot be less than start date {1}!',
    appointmentDateStart: 'Start date {0} cannot be less than the current date {1}!',
    appointmentResendMail: 'Do you want to resend email',
    appointmentTitleEmergency: 'Scheduled emergency care, patient {0}.',
    appointmentTitlePAT: 'Scheduled appointment for {0}, patient {2} and Dr (a) {1}.',
    appointmentTitlePATSM: 'Scheduled appointment, patient {1} and Dr (a) {0}.',
    appointmentTitleSMD: 'Scheduled appointment for {0}, patient {2} and Dr (a) {1}.',
    appointmentTitleSMDSM: 'Scheduled care, patient {1} and Dr (a) {0}.',
    confirm_sign_out: 'Confirm sign out',
    confirmCancel: 'Are you sure you want to cancel this query?',
    confirmCancelTitle: 'Confirm Cancellation',
    confirmPerformedManual: 'Are you sure you want to mark this query as Perform Manually?',
    confirmPerformedManualTitle: 'Confirm Perform Manually',
    copySuccess: 'Copied successfully',
    dialog_prescription_error: 'To proceed with the prescription, register your address in your professional profile',
    dialog_prescription_error_international_address:
      'Only Brazilian addresses are allowed to generate prescription, make sure your address is located in Brazil',
    dialogDeleteRangeText: 'Are you sure you want to delete this track?',
    dialogDeleteRangeTitle: 'Confirm the deletion',
    dialogDeleteSelectText: 'Are you sure you want to delete?',
    dialogFinishProfessionalProtocolTitle: 'Confirm the release of the professional from the virtual queue',
    dialogFinishProfessionalSelectText:
      'This action is used when the professional is unable to return to the home screen. Are you sure you want to continue?',
    dialogFinishRangeTitle: 'Close attendance',
    dialogFinishSelectText: 'This protocol is at {0}, are you sure you want to close it anyway?',
    display_guideline_for_professional_message:
      "By activating this functionality, you will be able to create guidelines to be displayed on the professional's screen during the service.",
    duplicate_schedules: 'Duplicate times on the same notification channel.',
    duplicated: 'There is already a register with this value',
    expired_session: 'Expired session',
    file_size_limit: 'The limit size of each file for upload is {0}MB',
    formErrorText: 'Inconsistent data.',
    formErrorTitle: 'Error',
    formSuccessText: 'It was done successfully.',
    full_access: 'Full access',
    info_appointment_deleted: 'This appointment was deleted in:',
    integrationManualMessageError: 'No active contract was found for this CPF',
    integrationManualMessageSuccess: 'Contract found and sent to integration',
    invalidMail: 'It must be a valid email.',
    isBlank: '{0} must not contain blank spaces.',
    isDuplicated: '{0} already exists.',
    isInvalid: '{0} is invalid',
    isRequired: '{0} is required.',
    isUnique: '{0} already exists',
    isValid: '{0} is invalid.',
    maxLength: 'The {0} must be a maximum of {1} characters.',
    maxValue: '{0} must be lower than {1}.',
    minLength: 'The {0} must be at least {1} characters.',
    minValue: '{0} must be greater than {1}.',
    my_profile: 'My Profile',
    new_event_subtitle: 'Select an option below and submit files/exams or links',
    no: 'No',
    no_access: 'No access',
    noData: 'No data available',
    notification_reminders_fields_mandatory:
      'All fields for sending a confirmation reminder notification are required.',
    payment_failure: 'Payment was not accepted, check if the data provided is correct',
    payment_in_analysis: 'Payment is in analysis. We will send a notification once its confirmed',
    payment_not_confirmed: 'Payment not found. Please, make sure the bank transaction was successful.',
    payment_success: 'Payment Success',
    preferential_prescription_message:
      'In case of choosing multiple providers, the option selected as preferred will be shown to the professional as preferred.',
    prescription_error: 'Error while opening prescription. Please, try again later.',
    recurrenceChangeDate: '{0} cannot be less than the Start Date: {1} !',
    saving_prescription: 'Saving prescription file... Please wait.',
    show_all_appointments: 'Professional creates/visualizes service for any professional',
    show_all_consultation: 'Professional views any query from any professional',
    show_only_owner_appointments: 'Professional creates/views only his schedules',
    show_only_owner_consultation: 'Show only owner consultation',
    sign_out: 'Sign Out',
    special_characters_are_not_allowed: 'Special characters are not allowed.',
    successfully_changed: 'Changed successfully.',
    successfully_created: 'Created successfully.',
    successfully_deleted: 'Successfully deleted.',
    termPendingAccept: 'Terms of use of the system pending reading and acceptance.',
    time_expired_session: 'Time expired session',
    unallowed_access_to_page: 'You are not allowed to access this page',
    upload_at_least_1_file: 'Upload at least 1 file',
    validate_error: 'Validation error. Please check the form fields again',
    value_attendance_minimum: 'The minimum service charge is R $ 8.00 (eight reais)',
    wiping_prescription: 'Wiping prescription... Please Wait.',
    yes: 'Yes',
  },
  pages: {
    appointment: {
      appointment_recurrence: 'Recurrence',
      appointment_time: 'Appointment time',
      calendar: 'Calendar',
      detail: 'Scheduling',
      duration: 'Duration',
      edit: 'Calendar',
      end: 'Finish',
      init: 'Start',
      list: 'Schedules',
      list_appointments: 'List',
      minutes: 'minutes',
      new: 'New Schedule',
    },
    company: {
      appointment: 'Appointment',
      appointment_page: {
        allow_professional_change_background: 'Allow professional to change background image?',
        default_background_imagem: 'Default background image',
      },
      basic_data: 'Basic Data',
      company: 'Company',
      custom_messages: 'Custom Messages',
      detail: 'Details',
      edit: 'Edit',
      landing: 'Site',
      landing_page: {
        allow_show_doctors: 'Display professionals on the site?',
        enabled: 'Web site enabled?',
        external_page: {
          enabled: 'External page enabled?',
          name: 'Session name',
          name_hint: 'Name as it will appear on your page',
          title: 'External page',
          url: 'External page URL',
          url_hint: 'External page URL that will appear on your page',
        },
        labels: {
          add_image: 'Add Image',
          bold: 'Bold',
          center_text: 'Text center',
          error_add_image: 'First add an image of who we are in the PICTURES section.',
          highlight: 'Highlight',
          horizontal_line: 'Horizontal Line',
          italic: 'Italic',
          justify_text: 'Text Justify',
          left_text: 'Text Left',
          ordered_list: 'Ordered List',
          paragraph: 'Paragraph',
          right_text: 'Text Right',
          strike: 'Strike',
          subtitle: 'Subtitle',
          title: 'Title',
          unordered_list: 'Unordered List',
        },
        seo: {
          description: 'Description',
          description_hint: 'Description of your page that should appear at Googles search',
          meta_tags: 'Meta tags',
          meta_tags_hint: 'Key words that should be searched on Google. (Separate by comma)',
          title: 'SEO',
        },
        show_attendance_value: 'Show attendance value?',
        social_media: {
          describe: "Enter only the URL's you want to display on the site",
          facebook: 'Facebook',
          instagram: 'Instagram',
          linkedin: 'LinkedIn',
          telegram: 'Telegram',
          title: 'Social media',
          twitter: 'Twitter',
          whatsapp: 'Whatsapp',
          youtube: 'Youtube',
        },
        who_we_are: 'Who we are',
      },
      list: 'Companies',
      new: 'New Company',
      pictures: 'Pictures',
      screening: 'Screening',
      shortcuts: 'Shortcuts',
      theme: 'Theme',
    },
    consultation: {
      lang: 'en-US',
      list: 'Consultations',
    },
    dashboard: {
      main: 'Dashboards',
      operational: {
        metric: {
          PERSON_ATTENDANCE: 'Average waiting time at emergency room',
          PERSON_ATTENDANCE_AVERAGE_TIME: 'Average consultation time in emergency care',
          PERSON_ATTENDANCE_CID10: "Top 10 CID's",
          PERSON_ATTENDANCE_COMPLETE_FLOW: 'Average complete flow time in emergency care',
          PERSON_ATTENDANCE_COUNT: 'Appointments by status',
          PERSON_ATTENDANCE_SOURCE: 'Appointments by channel',
          PERSON_ATTENDANCE_UPSHOT: 'Appointments by outcome',
          PERSON_DOCTOR_NOTES: 'Number of medical certificate',
          PERSON_MEDICAL_NOTES: 'Number of exams',
          PERSON_PRESCRIPTIONS: 'Number of medical prescriptions',
          PERSON_PROTOCOL: 'Open Protocols x Appointments Finished',
        },
        source: {
          API: 'API',
          CLINIC: 'Clinic',
          EMERGENCY: 'Emergency',
          PATIENT: 'Self Schedule'
        },
        status_appointment: {
          AGE: 'Scheduled',
          APP: 'Pre-schedule',
          CAN: 'Canceled',
          CAS: 'Canceled By System',
          NRE: 'Not Carried Out',
          REA: 'Finished',
          REM: 'Performed Manually',
        },
        title: 'Performance Operational'
      },
      quality: {
        metric: {
          PERSON_ATTENDANCE: 'Waiting time for the consultation to start',
          PERSON_INTERRUPTION: 'Number of queries interrupted due to technical problems',
          PERSON_LOGIN: 'Time to login',
          PERSON_NOSHOW: 'Number of queries not completed due to lack of interaction',
          PERSON_NPS: 'Net promoter score',
          PERSON_REESTABLISHED: 'Number of consultations reestablishing the connection due to technical problems'
        },
        title: 'Quality Service',
      },
    },
    doctor: {
      detail: 'Professionals Details',
      edit: 'Edit Professionals',
      list: 'Professionals',
      new: 'New Professional',
    },
    emergency: {
      attendance_now: 'Emergency Care',
      attendances: 'Attendances',
      dashboard: 'Dashboard',
      date_init: 'Start date',
      emergency_label: 'Emergency',
      entry_date: 'Entry date',
      flow_history_steps: {
        FLOW_FINISHED_BY_MANAGER: 'Finished by manager',
        FLOW_ON_ATTENDANCE: 'In attendance',
        FLOW_ON_POST_ATTENDANCE: 'Post attendance',
        FLOW_PERSON_ARRIVAL: 'Patient arrived',
        FLOW_PERSON_CHECK_RESOURCES: 'Teste resources',
        FLOW_PERSON_COMPLAINT_REPORTED: 'Patient checked in',
        FLOW_PERSON_FINISH_ATTENDANCE: 'Patient left emergency',
        FLOW_PERSON_FINISHED_BY_SYSTEM: 'Patient Inactivated',
        FLOW_PERSON_MEASUREMENT: 'Patient collecting vital signs',
        FLOW_PERSON_ON_END: 'Finished attendance',
        FLOW_PERSON_ON_NPS: 'Patient in NPS',
        FLOW_PERSON_PAYMENT_APPROVED: 'Payment was approved',
        FLOW_PERSON_PAYMENT_REPROVED: 'Payment was reproved',
        FLOW_PERSON_PERFORMING_PAYMENT: 'Patient performing payment',
        FLOW_PERSON_READY_TO_BE_ATTENDED: 'Patient in pre-attendance',
        FLOW_PERSON_REDIRECTED_IN_PERSON_ATTENDANCE: 'Patient redirect in person attendance',
        FLOW_PERSON_REPORT_COMPLAINT: 'Patient to checkin',
        FLOW_PERSON_WAITING_NEXT_GROUP: 'Patient in line',
        FLOW_PROFESSIONAL_END_ATTENDANCE: 'Professional finished attendance',
        FLOW_PROFESSIONAL_FINISHED_BY_MANAGER: 'Professional terminated by manager (flow reset)',
        FLOW_PROFESSIONAL_FINISHED_BY_SYSTEM: 'Professional Inactivated',
        FLOW_PROFESSIONAL_READY_TO_ATTEND: 'Professional in pre-attendance',
        IN_ATTENDANCE: '{0} in attendance',
      },
      flow_steps: {
        FLOW_FINISHED_BY_MANAGER: 'Finished by manager',
        FLOW_ON_ATTENDANCE: 'In attendance',
        FLOW_ON_POST_ATTENDANCE: 'Post attendance',
        FLOW_PERSON_ARRIVAL: 'Patient joined',
        FLOW_PERSON_CHECK_RESOURCES: 'Teste resources',
        FLOW_PERSON_COMPLAINT_REPORTED: 'Checkin',
        FLOW_PERSON_FINISH_ATTENDANCE: 'Finished attendance',
        FLOW_PERSON_FINISHED_BY_SYSTEM: 'Inactivated',
        FLOW_PERSON_MEASUREMENT: 'Collection vital signs',
        FLOW_PERSON_ON_END: 'Finished',
        FLOW_PERSON_ON_NPS: 'Patient in NPS',
        FLOW_PERSON_PAYMENT_APPROVED: 'Payment Approved',
        FLOW_PERSON_PAYMENT_REPROVED: 'Payment Reproved',
        FLOW_PERSON_PERFORMING_PAYMENT: 'On payment',
        FLOW_PERSON_READY_TO_BE_ATTENDED: 'Pre-attendance',
        FLOW_PERSON_REDIRECTED_IN_PERSON_ATTENDANCE: 'Redirect in person attendance',
        FLOW_PERSON_REPORT_COMPLAINT: 'Checkin',
        FLOW_PERSON_WAITING_NEXT_GROUP: 'In line',
        FLOW_PROFESSIONAL_END_ATTENDANCE: 'Finished attendance',
        FLOW_PROFESSIONAL_FINISHED_BY_MANAGER: 'Professional terminated by manager (flow reset)',
        FLOW_PROFESSIONAL_FINISHED_BY_SYSTEM: 'Inactivated',
        FLOW_PROFESSIONAL_READY_TO_ATTEND: 'Pre-attendance',
        IN_ATTENDANCE: '{0} in attendance',
      },
      group: 'Queues',
      history: 'History',
      ind_pac: 'Status pac',
      ind_prof: 'Status prof',
      level: {
        PRIMARY: 'First Level',
        SECONDARY: 'Forward',
      },
      patients_in_attendance: 'In Line',
      patients_in_line: 'Pacients in line',
      position: 'Position',
      professional_reset_successfully: 'Professional flow has been successfully reset',
      professionals: 'Professionals',
      professionals_in_line: 'Available professionals',
      protocol: 'Protocol',
      protocol_closed_error: 'There was an error when finishing the attendance with protocol {0}.',
      protocol_closed_successfully: 'The attendance with protocol {0} was finished successfully.',
      queue: {
        invalid_edit_queue:
          'Attention! To edit this queue, check that it contains at least one first-level queue and enabled!',
        invalid_remove_queue:
          'Attention! To remove this queue, check that it contains at least one first-level queue and enabled!',
      },
      risk: 'Risk',
      status_attendance: 'Status',
      status_virtual_line: 'Status Virtual Line',
      virtual_line: 'Virtual Line',
    },
    finance: {
      edit: 'Settings',
      financial: 'Financial',
      payments: 'Payments',
    },
    home: 'Home page',
    login: 'login',
    login_page: {
      back: 'Back',
      close: 'Close',
      continue: 'Continue',
      forgot_password: 'Forgot password?',
      forgot_password_message: 'We will send a recovery link to your user',
      log_in: 'Log in',
      login_to_your_account: 'Login to your account',
      password: 'Password',
      send_mail: 'Send Email',
    },
    message: {
      archive: 'Archive',
      archivedSuccess: 'Successfully archived.',
      body: 'Body',
      detail: 'Detail',
      file_error_send: 'Error uploading file(s)',
      inbox: 'Inbox',
      late: 'Late',
      list: 'Messages',
      new: 'Nova mensagem',
      new_message: 'New message',
      new_message_success: 'Message sent successfully',
      new_response: 'New response',
      on_time: 'On time',
      recipient_not_found: 'Recipient not found',
      reply_message: 'Reply message',
      response_success: 'Response successfully sent',
      seen: 'Views',
      subject: 'Subject',
      unarchivedSuccess: 'Successfully unarchived.',
    },
    pageNotFound: 'Page not found',
    patient: {
      detail: 'Patient Details',
      edit: 'Edit Patient',
      list: 'Patients',
      new: 'New Patient',
      remove_with_dependents:
        'To delete this Patient, you need to remove the relationship with dependents registered in the system.',
    },
    plan: {
      afterCompletingTheFirstPayment: 'After completing the first payment',
      atTheSigningOfTheContract: 'At the signing of the contract',
      detail: 'Plan',
      edit: 'Edit plan',
      list: 'Plans',
      new: 'New plan',
      planWithPatientError:
        'To delete this plan, you need to remove the relationship with the patients registered in the system.',
      whenWillSubscriberRegisteredInSystem: 'When will the subscriber be registered in the system ?',
    },
    report: {
      appointment: 'Appointment',
      notifications: 'Notifications',
      protocols: 'Protocols',
      rating: 'Ratings',
      term: 'Terms',
      title: 'Report',
    },
    schedule: {
      action: 'Action',
      allDay: 'Full Day',
      allows_patient_to_schedule: 'Specify deadline for making appointments available',
      at: 'at',
      availability: 'Availability',
      blocked: 'Calendar Block',
      cancel: 'Cancel',
      date: 'Data',
      dateEnd: 'End Date',
      dateStart: 'Start Date',
      days: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      delete: 'Delete',
      doctors: 'Professional',
      errors: {
        already_exists: 'Date already exists',
        appointments_conflicts: 'Attendance Conflicts',
        greater_limit_days: 'Date range cannot be greater than 35 days',
        invalid_date: 'Invalid date',
        invalid_time: 'Invalid time',
        less_current_date: 'Date must be greater than current one',
        less_selected_start_date: 'Insert a date greater than the date selected in the calendar',
        less_start_time: 'End Time must be greater than start time',
        out_of_schedule: 'Selected time is out of schedule',
        registered_appointments: 'Attention! There are registered appointments for this time!',
        time_conflict: 'Time conflicts!',
        time_default: 'Time must me be greater than standard attendance time',
        wont_be_canceled: 'Any appointments will be excluded. It must be done manually.',
      },
      extra: 'Extraordinary',
      fixed: 'Fixed',
      fixed_schedule: 'Fixed Schedule',
      flexible: 'Flexible',
      holidays: 'Holidays',
      hourEnd: 'Hora Fim',
      hourStart: 'Start Time',
      ignoreHolidays: 'Skip holidays at the clinic',
      name: 'Name',
      new: 'New',
      save: 'Save',
      schedule_availability: 'Schedule availability',
      search: 'Search',
      timeAllowed: 'Allow Patient to Schedule',
      type: 'Types',
      verify_date: 'Please check the "data" field',
      verify_fields: 'Please check the highlighted fields and try again',
      verify_name: 'Please check the "name" field',
    },
    scheduleTime: 'schedule',
    screening: {
      default_subtype: 'Default',
      edit_form: 'Screening Edit',
      field_model: 'Field model',
      field_models: {
        CHECK_BOX: 'Selection boxes',
        RADIO_BUTTON: 'Multiple choices',
        SELECT: 'Suspended list',
        TEXT_AREA: 'Long text',
        TEXT_FIELD: 'Short text',
      },
      field_title: 'Field title',
      new_form: 'New Screening',
      no_title_question: 'No title question',
      option: 'Option',
      protocol: {
        aacr: 'AACR - Acolhimento com Avaliação e Classificação de Risco',
        mts: 'Manchester Protocol',
      },
      required: 'Required',
      settings: {
        patient_care_order: 'Patient care order',
        print_bracelet: 'Imprimir pulseira',
        protocol_risk_classification: 'Protocol - Risk classification',
        risk_targeting: 'Risk targeting',
        risk_targeting_colors: {
          blue: 'Blue',
          green: 'Green',
          orange: 'Orange',
          red: 'Red',
          yellow: 'Yellow'
        },
        risk_targeting_info: 'For "In-Person Appointments," you can set up a personalized message to guide the patient on the next steps (Settings > Custom Messages).',
        use_in_emergency: 'Display on virtual line',
        use_in_self_schedule: "Display on patient's self schedule",
        use_in_waiting_room: 'Display on attendance waiting room',
      },
      subtype: 'Qeue/Specialty',
      text_response: 'Text response',
      type: 'Screening type',
    },
    settings: {
      callbacks: {
        apppointment: {
          participantConnected: 'participantConnected - The participant entered the service',
          participantDisconnected: 'participantDisconnected - The participant left the service',
          participantWaitingRoom: 'participantWaitingRoom - The participant entered the waiting room',
          sessionFinished: 'sessionFinished - The primary participant ended the session',
          systemFinished: 'systemFinished - The system ended the query',
        },
        emergency: {
          manager: {
            MANAGER_FINISH_FLOW: 'MANAGER_UPDATE_DASHBOARD - Dashboard Update',
            MANAGER_START_FLOW: 'MANAGER_START_FLOW - Virtual queue started',
            MANAGER_UPDATE_DASHBOARD: 'MANAGER_FINISH_FLOW - Protocol Manual Finalization',
          },
          person: {
            PERSON_COMPLAINT_REPORTED: 'PERSON_COMPLAINT_REPORTED - Answered pre-consultation anamnesis',
            PERSON_ENTER_EMERGENCY: 'PERSON_ENTER_EMERGENCY - Accessed Virtual Queue',
            PERSON_FINISH_ATTENDANCE: 'PERSON_FINISH_ATTENDANCE - Completed service',
            PERSON_FINISH_BY_SYSTEM: 'PERSON_FINISH_BY_SYSTEM - Service completed by the system',
            PERSON_FORWARD_ATTENDANCE: 'PERSON_FORWARD_ATTENDANCE - Forwarded to another queue',
            PERSON_LEAVE_EMERGENCY: 'PERSON_LEAVE_EMERGENCY - Got out of line',
            PERSON_PLACE_IN_LINE: 'PERSON_PLACE_IN_LINE - Update position in queue',
            PERSON_READY_TO_ATTENDANCE: 'PERSON_READY_TO_ATTENDANCE - Entered the pre-service room',
            PERSON_START_ATTENDANCE: 'PERSON_START_ATTENDANCE - Service started',
          },
          professional: {
            PROFESSIONAL_ASK_NEXT: 'PROFESSIONAL_ASK_NEXT - Next patient',
            PROFESSIONAL_FINISH_ATTENDANCE: 'PROFESSIONAL_FINISH_ATTENDANCE - Completed service',
            PROFESSIONAL_FINISH_BY_SYSTEM: 'PROFESSIONAL_FINISH_BY_SYSTEM - Service completed by the system',
            PROFESSIONAL_LEAVE_EMERGENCY: 'PROFESSIONAL_LEAVE_EMERGENCY - Got out of line',
            PROFESSIONAL_READY_TO_ATTENDANCE: 'PROFESSIONAL_READY_TO_ATTENDANCE - Entered the pre-service room',
            PROFESSIONAL_REPORT_CHECKIN: 'PROFESSIONAL_REPORT_CHECKIN - Checkin na Fila',
            PROFESSIONAL_START_ATTENDANCE: 'PROFESSIONAL_START_ATTENDANCE - Service started',
          },
        },
        title: {
          event_appointment: 'Attendance Events',
          event_appointment_manager: 'Virtual Queue events - Manager',
          event_appointment_patient: 'Virtual Queue events - Patient',
          event_appointment_professional: 'Virtual Queue events - Professional',
        },
      },
      company: 'Company',
      custom_messages: 'Custom Messages',
      developer: 'Developer',
      edit: 'Settings',
      general: 'General',
      term: 'Term',
    },
    tag: {
      detail: 'Group',
      edit: 'Edit group',
      list: 'Groups',
      new: 'New group',
      tagWithPatientError: 'To delete this group you need to remove the relationship with the patients registered in the system.',
      title: 'Group name'
    },
    term: {
      ask_all_attendances: 'Ask in all attendances',
      description: 'Description',
      disabled: 'Disabled',
      enabled: 'Enabled',
      last_update: 'Last Updated',
      require_whole_attendances: 'Require in whole attendances',
      term_use: 'Term use',
      where_to_use: {
        ALL: 'All',
        ATTENDANCE: 'Attendance',
        EMERGENCY: 'Emergency',
        label: 'Where to Use',
        LOGIN: 'Login',
      },
    },
    user: {
      detail: 'Details',
      edit: 'Edit',
      list: 'Users',
      managedProfessionals: 'Professionals managed by the user',
      manageProfessional: 'Add professionals managed by the user',
      new: 'New User',
    },
    voucher: {
      detail: 'Voucher',
      edit: 'Edit voucher',
      list: 'Vouchers',
      new: 'New voucher',
    },
  },
  template_mail: {
    APPOINTMENT_CANCELLED_EMAIL: 'Appointment Canceled',
    APPOINTMENT_CONFIRM_PERSON: 'Attendance confirm',
    APPOINTMENT_CONFIRM_PERSON_SMS: 'Attendance confirm',
    APPOINTMENT_CONFIRM_PERSON_WHATSAPP: 'Attendance confirm',
    APPOINTMENT_CONFIRMED_SMS: 'New Appointment',
    APPOINTMENT_CONFIRMED_WHATSAPP: 'New Appointment',
    APPOINTMENT_EMERGENCY_CONFIRMED_WHATSAPP: 'Confirmation reminder - Emergency Service',
    APPOINTMENT_EMERGENCY_NO_CHARGED_PATIENT: 'Confirmation reminder - Emergency Service',
    APPOINTMENT_NO_CHARGED_PATIENT: 'New Appointment',
    APPOINTMENT_NO_CHARGED_PROFESSIONAL: 'New Appointment',
    APPOINTMENT_PAYMENT_APPROVED_COMPANY: 'Attendance Payment',
    APPOINTMENT_PAYMENT_APPROVED_PERSON: 'Confirmed Appointment Payment',
    APPOINTMENT_PAYMENT_APPROVED_PROFESSIONAL: 'Confirmed Appointment Payment',
    APPOINTMENT_PAYMENT_PENDING_PATIENT: 'Pending Appointment Payment',
    APPOINTMENT_PAYMENT_PENDING_PATIENT_PAYER: 'Appointment Charge',
    APPOINTMENT_PAYMENT_PENDING_PROFESSIONAL: 'Pending Appointment Payment',
    APPOINTMENT_PAYMENT_PENDING_PROFESSIONAL_PAYER: 'Appointment Charge',
    APPOINTMENT_PAYMENT_PENDING_RESPONSIBLE: 'Appointment Charge',
    APPOINTMENT_PAYMENT_PENDING_SMS: 'Appointment Charge',
    APPOINTMENT_PAYMENT_PENDING_WHATSAPP: 'Appointment Charge',
    APPOINTMENT_PAYMENT_REPROVED_COMPANY: 'Reproved Appointment Payment ',
    APPOINTMENT_POST_ATTENDANCE_PERSON: 'Post Attendance',
    APPOINTMENT_RECURRENCE_PERSON: 'New appointment with recurrence',
    APPOINTMENT_REMOVED_PERSON: 'Removed from Appointment',
    APPOINTMENT_REMOVED_PROFESSIONAL: 'Removido de Agendamento',
    BENEFITCLUB_ACTION_FAILED: 'Benefit club integration failure',
    BENEFITCLUB_ADD_CUSTOMER: 'New benefit club user',
    dav_emergency_ec_antepenult_reminder: 'Second to last appointment reminder - Emergency Service',
    dav_emergency_ec_last_reminder: 'Last appointment reminder - Emergency Service',
    dav_emergency_ec_penult_reminder: 'Penultimate appointment reminder - Emergency Service',
    EMERGENCY_NEW_PERSON: 'Emergency Access',
    EMERGENCY_PAYMENT_APPROVED_COMPANY: 'Emergency Payment',
    EMERGENCY_PAYMENT_APPROVED_PATIENT: 'Emergency Payment',
    FINANCIAL_PROVIDER_CNPJ_PLAN: 'New Business Plan contract',
    GALAXY_WEBHOOK_ACTION_FAILED: 'Integration Failure Warning',
    NEW_DIAGNOSTIC_EMAIL_NOTIFICATION: 'New medical record',
    NEW_DIAGNOSTIC_EMAIL_NOTIFICATION__MEDICAL_REFERRAL: 'New medical record - Medical referral',
    NEW_MESSAGE_NOTIFICATION_EMAIL: 'New message reminder',
    NOTIFICATION_REMINDER_EMAIL: 'Appointment Reminder',
    NOTIFICATION_REMINDER_EMERGENCY_EMAIL: 'Appointment Reminder - Emergency Service',
    NOTIFICATION_REMINDER_EMERGENCY_SMS: 'Appointment Reminder - Emergency Service',
    NOTIFICATION_REMINDER_EMERGENCY_WHATSAPP: 'Appointment Reminder - Emergency Service',
    NOTIFICATION_REMINDER_SMS: 'Appointment Reminder',
    NOTIFICATION_REMINDER_WHATSAPP: 'Appointment Reminder',
    NPS_EMERGENCY_SERVICE_MAIL: 'Emergency Attendance Rating',
    NPS_SERVICE_MAIL: 'Attendance Rating',
    PLAN_ACTIVE_STATUS_NOTIFICATION: 'Status change from plan to active',
    PLAN_BLOCK_STATUS_NOTIFICATION: 'Status change from plan to blocked',
    PLANCHECK_SCHEDULE_NOTIFICATION: 'Changing the status of patients',
    USER_CREATED_COMPANY: 'New User Company',
    USER_CREATED_PERSON: 'New User',
    USER_CREATED_PERSON_NOTIFY_COMPANY: 'New User',
    USER_CREATED_PROFESSIONAL: 'New User',
    USER_CREATED_PROFESSIONAL_DOCTOR: 'New User',
    USER_FORGOT_PASSWORD_PERSON: 'Forgot Password',
    USER_FORGOT_PASSWORD_PROFESSIONAL: 'Forgot Password',
    USER_FORGOT_PASSWORD_PROFESSIONAL_COMPANY: 'Forgot Password',
    USER_UPDATED_PERSON_NEW_LOGIN: 'Login Updated',
    USER_UPDATED_PERSON_OLD_LOGIN: 'Login Updated',
    USER_UPDATED_PROFESSIONAL_DC_NEW_LOGIN: 'Login Updated',
    USER_UPDATED_PROFESSIONAL_DC_OLD_LOGIN: 'Login Updated',
    USER_UPDATED_PROFESSIONAL_NEW_LOGIN: 'Login Updated',
    USER_UPDATED_PROFESSIONAL_OLD_LOGIN: 'Login Updated',
    VOUCHER_PARTICIPANT_NOTIFICATION: 'Voucher',
    VOUCHER_PARTICIPANT_NOTIFICATION_SMS: 'Voucher',
    VOUCHER_PARTICIPANT_NOTIFICATION_WHATSAPP: 'Voucher',
  },
  title: {
    appointment: {
      companion_id: 'Escort',
      description: 'Service Description',
      doctor: 'Professionals',
      doctor_id: 'Professional',
      end_date: 'End Date',
      end_date_time: 'End Date / Time of Service',
      end_time: 'End Time',
      medical_assistent: 'Assistant Physician',
      notes: 'Consultation notes',
      patient_id: 'Patient',
      role: 'Participant Type',
      start_date: 'Start Date',
      start_date_time: 'Service Start Date / Time',
      start_time: 'Start Time',
      status_appointment: 'Schedule Status',
      title: 'Service Title',
      type: 'Type of Service',
      upload_image: 'Image uploader',
    },
    company: {
      birth_date: 'Date of Birth',
      business_contact: 'Business contact',
      cell_phone: 'Mobile',
      city: 'City',
      cnpj: 'CNPJ',
      comission_type: 'Commission type',
      comission_value: 'Commission amount',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      edit: 'Edit',
      expiration_date: 'Expiration Date',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Fantasy Name',
      neighborhood: 'Neighborhood',
      number: 'Number',
      parent_id: 'Patient financially responsible for the patient',
      photo: 'Photo',
      plan_id: 'plan ID',
      region: 'Federal Unit',
      representative_id: 'representative ID',
      social_name: 'Social Reason',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      Filter: 'Filter',
      gender: 'Sex',
      id: 'Identifier',
      mail: 'E-mail',
      medical_specialty: 'Professional Specialty',
      name: 'Name',
      neighborhood: 'Neighborhood',
      number: 'Number',
      picture: 'Photo',
      professional_registration: 'Professional record',
      region: 'Federal Unit',
      registration_state: 'UF of the Registry',
      status: 'Status',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Address',
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      country: 'Country',
      cpf: 'CPF',
      dependent_limit: 'Dependent limit',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Name',
      neighborhood: 'Neighborhood',
      number: 'Number',
      parent_id: 'Patient financially responsible for the patient',
      picture: 'Photo',
      plan: 'Plan',
      profession: "Patient's occupation",
      region: 'Region',
      status: 'Status',
      status_plan: 'Status plan',
      street: 'Street',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indicates whether this plan allows additional minutes for recording',
      additional_minute_value: 'Value of the additional minute for consulting the plan',
      additional_minutes_allowed: 'Indicates whether this plan allows additional minutes for consultation',
      additional_recorded_minute_value: 'Value of the additional minute of recording the plan',
      attendance_average_minutes: 'Average number of consultation minutes for contract verification',
      attendance_average_minutes_recorded: 'Average number of minutes of recording for contract verification',
      attendance_value: 'Consultation Value',
      bill_type: 'Plan type',
      commission_type: 'Commission Type',
      commission_value: 'Commission Amount',
      corporate_document_number: 'Document Number',
      corporate_plan: 'Corporate Plan?',
      description: 'Plan Name',
      duration_days: 'Indicates the number of days the plan lasts',
      initial_attendance_quantity: 'Initial number of queries for this range',
      max_additional_minute: 'Maximum number of additional minutes for consultation',
      max_additional_recorded_minute: 'Maximum number of additional minutes for recording',
      max_attendance_minutes: 'Indicates the maximum duration of minutes of the consultation',
      minutes_quantity: 'Number of minutes of consultation of the plan',
      minutes_value: 'Plan consultation minute value',
      name: 'Plan Name',
      recorded_minute_value: 'Plan recording minute value',
      recorded_minutes_quantity: 'Number of minutes of recording the plan',
      with_benefitclub_offer: 'Plan with benefit club offer?',
      with_secure_offer: 'Plan with secure offer?',
    },
    settings: {
      allow_access_appointment_before: 'Will allow access to the consultation room before the scheduled start time',
      allows_patient_to_schedule: 'It will allow the patient to schedule the appointment',
      allows_patient_to_schedule_a_return_visit: 'Allow patient to request return from scheduled appointments',
      allows_patient_to_schedule_time_after: 'Maximum number of days to schedule in the future',
      allows_patient_to_schedule_time_before: 'Time before the scheduled time is allowed',
      appointment_duration: 'Average session time in minutes',
      callback_url_for_query_notifications: 'Callback URL for session notifications',
      color_primary: 'Defines the primary color of the application, Ex: TOP and Buttons',
      deadline_to_return: 'Deadline for requesting return',
      deny_access_appointment_after:
        'When you exceed the configured time after the start of the consultation, no access to it will be allowed',
      ended_appointment_after_duration:
        'Defines if after the maximum consultation time has ended, the consultation should end automatically',
      finance_mail_address: 'E-mail address. Ex: contact@company.com',
      hour: 'Defines the time that the notification will be sent before the scheduled appointment time',
      intermediary_account_number: 'Intermediary account number',
      max_appointment_duration: 'Maximum consultation time in minutes, if the consultation time is reached',
      minutes: 'Defines the minutes that the notification will be sent before the scheduled appointment time',
      module_used: 'Do you use an office module?',
      period: 'Period',
      restore_color: 'Restore default color',
      select_color: 'Customize with your preferred color',
      show_countdown_timer: 'When X minutes are missing the countdown timer is displayed to the professional',
      time_inactivity_to_disconnect: 'Inactivity time in minutes to force disconnect from attendance',
    },
    user: {
      company_id: 'Company',
      mail: 'E-mail',
      name: 'Name',
      namecpf: 'Name | CPF',
    },
  },
  value: {
    active: 'Active',
    blocked: 'Blocked',
    inactive: 'Inactive',
    no: 'No',
    yes: 'Yes',
  },
};

export default enUS;
